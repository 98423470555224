function Index(props) {
  return (
    <>
      <section className="p-404">
        <div className="content-404">
          <div className="w-100">
            <h3>404</h3>
          </div>
          <div className="w-100">
            <b>Page not found!</b>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
