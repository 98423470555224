import { useRef, useEffect } from "react";
import TopProject from "./top-project";

function Index(props) {
  const { project, locale } = props;
  const videoContainerRef = useRef();

  useEffect(() => {
    let video = null;
    let mastheadOverlay = null;
    let vdomvInner = null;
    if (project.video_demo) {
      video = document.createElement("video");
      video.autoplay = true;
      video.loop = true;
      video.muted = true;
      video.setAttribute("playsinline", "true");
      video.setAttribute("class", "p-masthead__video");
      video.setAttribute(
        "poster",
        `https://img.youtube.com/vi/${project.youtube_id_demo}/default.jpg`,
      );

      const source = document.createElement("source");
      source.src = project.video_demo;
      source.type = "video/mp4";
      video.appendChild(source);
      videoContainerRef.current.appendChild(video);

      vdomvInner = document.createElement("div");
      vdomvInner.setAttribute("class", "p-video__mvinner sp");
      vdomvInner.innerHTML = `<h2>${
        locale == "en" && project.name_en ? project.name_en : project.name_jp
      }</h2>
                                    <p class="p-video__play p-video__sp">
                                        <a href="${project.video_demo}">
                                        <img src="${require("../../../assets/images/obj_play.png")}" alt=""></a>
                                    </p>`;
      videoContainerRef.current.appendChild(vdomvInner);
      mastheadOverlay = document.createElement("div");
      mastheadOverlay.setAttribute("class", "p-masthead__overlay sp");
      videoContainerRef.current.appendChild(mastheadOverlay);
    }

    return () => {
      if (
        videoContainerRef.current &&
        videoContainerRef.current.contains(video)
      ) {
        videoContainerRef.current.removeChild(video);
      }
      if (
        videoContainerRef.current &&
        videoContainerRef.current.contains(vdomvInner)
      ) {
        videoContainerRef.current.removeChild(vdomvInner);
      }
      if (
        videoContainerRef.current &&
        videoContainerRef.current.contains(mastheadOverlay)
      ) {
        videoContainerRef.current.removeChild(mastheadOverlay);
      }
    };
  }, [project]);

  useEffect(() => {
    const jQuery = window.$;
    //play youtube
    jQuery('a[href*="youtube.com/watch"]').magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 200,
      disableOn: 1000,
      preloader: false,
      iframe: {
        patterns: {
          youtube: {
            index: "youtube.com",
            id: "v=",
            src: "https://www.youtube.com/embed/%id%?autoplay=1&mute=1&rel=0&showinfo=0",
          },
        },
      },
    });
  }, []);

  return (
    <>
      <div id="p-video__wrapper" className="p-video__wrapper">
        <div className="p-masthead">
          <div class="p-masthead__video__wrapper" ref={videoContainerRef}></div>
          <div class="p-masthead__overlay pc"></div>
          <div class="p-video__mvinner pc">
            <h2>
              {locale == "en" && project.name_en
                ? project.name_en
                : project.name_jp}
            </h2>
            <p class="p-video__play p-video__pc">
              <a
                className="popup-iframe"
                href={`https://www.youtube.com/watch?v=${project.youtube_id_demo}`}
              >
                <img
                  src={require("../../../assets/images/obj_play.png")}
                  alt="PLAY"
                />
              </a>
            </p>
          </div>
        </div>
        <TopProject {...props} />
      </div>
    </>
  );
}

export default Index;
