function Index(props) {
  const { setting, locale } = props;

  return (
    <>
      <div className="section-head">
        <section className="p-mv">
          <img src={setting?.header_image} alt="" />
        </section>
      </div>
    </>
  );
}

export default Index;
