import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API_URL;

export async function _post({ url, data }) {
  return _request({ url, method: "post", data });
}

export async function _get({ url, params }) {
  return _request({ url, method: "get", params });
}

export async function _put({ url, data }) {
  return _request({ url, method: "put", data });
}

export async function _delete({ url, data }) {
  return _request({ url, method: "delete", data });
}

async function _request({ url, method, params = null, data = null, headers }) {
  if (!headers) {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  let token = window.$cookies.get("token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    return await axios({
      url,
      method,
      headers,
      data,
      params,
    });
  } catch (error) {
    throw error;
  }
}
