import { useEffect, useState } from "react";
import Selection from "../../../components/form/selection";
import {
  CONTRACT_ERC165_ABI,
  CONTRACT_WETH_ABI,
  CONTRACT_WETH_ADDRESS,
  OPENSEA_URL,
  IS_TESTNET_ENV,
} from "src/config";
import {
  web3ConnectionWallet,
  checkSwitchNetwork,
  web3Connection,
  checkChainAvailable,
} from "../../../provider";
import Modal from "../../../components/modal";
import { useTranslation } from "react-i18next";
import WalletProvider from "../../../hoc/WalletProvider";
import Spinner from "../../../components/spinner";
import CheckedDefault from "../../../components/spinner/checked-default";
import CheckedSuccess from "../../../components/spinner/checked-success";
import { projectPurchase } from "../../../actions/projectAction";
import { projectAction } from "src/actions";
import iconOpensea from "src/assets/images/opensea-logo.svg";

function Index(props) {
  const {
    data,
    chain_type,
    profile,
    locale,
    onConnectWallet,
    connectedAddress,
  } = props;
  const { t } = useTranslation();
  const [extendExpDate, setExtendExpDate] = useState(false);
  const [disabledPurchase, setDisabledPurchase] = useState(false);
  const [optionQty, setOptionQty] = useState([]);
  const [qty, setQty] = useState(1);
  const [beforeRecruit, setBeforeRecruit] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState({
    show: false,
    transfering: 0,
    minting: 0,
    type: "success",
    text: "",
    errorStep: 0,
  });

  useEffect(() => {
    loadBlockLatest();
  }, []);

  useEffect(() => {
    let disableBtn = false;
    const now = window.$moment();
    const date = window.$moment(data.mint_end_datetime);
    const startDate = window.$moment(data.mint_start_datetime);
    if (now.isAfter(date)) {
      disableBtn = true;
    } else if (now.isBefore(startDate)) {
      disableBtn = true;
      setBeforeRecruit(true);
    }

    let initialQty = data.limit_support_qty - data.my_supported;
    if (data.unlimited_support_qty == 1) {
      initialQty = 100;
    }
    if (data.unlimited_max_support_qty == 0) {
      let available = data.max_support_qty - data.total_purchased;
      if (initialQty > available) {
        initialQty = available;
      }
    }
    let _options = [];
    for (let i = 1; i <= initialQty; i++) {
      _options.push({
        value: i,
        text: i,
      });
    }
    if (_options.length === 0) {
      _options.push({
        value: 0,
        text: 0,
      });
      disableBtn = true;
      setQty(0);
    } else {
      setQty(1);
    }
    setOptionQty(_options);
    if (disabledPurchase !== disableBtn) {
      setDisabledPurchase(disableBtn);
    }
  }, [data]);

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      return (event.returnValue = "");
    };

    if (purchaseStatus.minting === 1) {
      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    } else {
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    };
  }, [purchaseStatus.minting]);

  const remainDays = () => {
    if (beforeRecruit) {
      const date = window.$moment(data.mint_start_datetime);
      const now = window.$moment();
      const days = date.diff(now, "days");
      if (days > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.start_day", { count: days }),
            }}
          ></div>
        );
      } else {
        const seconds = date.diff(now, "seconds");
        const hrs = `${Math.floor(seconds / 3600)}`.padStart(2, "0");
        const mins = `${Math.floor((seconds % 3600) / 60)}`.padStart(2, "0");
        const secs = `${Math.floor(seconds % 60)}`.padStart(2, "0");
        if (hrs > 0) {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t("period.start_hour", {
                  hour: hrs,
                  minute: mins,
                }),
              }}
            ></div>
          );
        } else if (mins > 0) {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t("period.start_minute", {
                  count: Number(mins),
                }),
              }}
            ></div>
          );
        } else {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: t("period.start_second", {
                  count: Number(secs),
                }),
              }}
            ></div>
          );
        }
      }
    }
    const date = window.$moment(data.mint_end_datetime);
    const now = window.$moment();
    const days = date.diff(now, "days");

    // - More than 1 day: あとd日​
    // - Less than 1 day: あとh時間:mm分​
    // - Less than 1 hour: あとm分​
    // - Less than 1 min: あとs秒
    // Close: 終了
    if (now.isAfter(date)) {
      return (
        <>
          <span>{t("project.closed")}</span>
        </>
      );
    } else if (days > 0) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.close_day", { count: days }),
          }}
        ></div>
      );
    } else {
      const seconds = date.diff(now, "seconds");
      const hrs = `${Math.floor(seconds / 3600)}`.padStart(2, "0");
      const mins = `${Math.floor((seconds % 3600) / 60)}`.padStart(2, "0");
      const secs = `${Math.floor(seconds % 60)}`.padStart(2, "0");
      if (hrs > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_hour", {
                hour: hrs,
                minute: mins,
              }),
            }}
          ></div>
        );
      } else if (mins > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_minute", {
                count: Number(mins),
              }),
            }}
          ></div>
        );
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_second", {
                count: Number(secs),
              }),
            }}
          ></div>
        );
      }
    }
  };

  const remainDayFull = () => {
    let format = "YYYY/MM/DD HH:mm:ss";
    if (beforeRecruit) {
      const date = window.$moment(data.mint_start_datetime);
      const strDate = date.format(format);
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.start_datetime", {
              datetime: strDate,
            }),
          }}
        ></div>
      );
    }
    const date = window.$moment(data.mint_end_datetime);
    const strDate = date.format(format);
    if (date.isBefore()) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.closed_datetime", {
              datetime: strDate,
            }),
          }}
        ></div>
      );
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t("period.close_datetime", {
            datetime: strDate,
          }),
        }}
      ></div>
    );
  };

  const handleSelectQty = (e) => {
    setQty(e.target.value);
  };

  const handlePurchase = async (collectionId) => {
    if (!data.contract_address || qty === 0) {
      return;
    }

    const collection = await projectAction.getProjectCollection(collectionId);
    const {
      total_purchased,
      max_support_qty,
      limit_support_qty,
      unlimited_max_support_qty,
      unlimited_support_qty,
      my_supported,
    } = collection;

    let available = limit_support_qty - my_supported;
    if (unlimited_support_qty == 1) {
      available = 100;
    }
    if (unlimited_max_support_qty == 0) {
      available = max_support_qty - total_purchased;
    }
    if (available < qty) {
      if (available == 0) {
        setDisabledPurchase(true);
      }
      props.loadProjectDetail();
      return;
    }

    setDisabledPurchase(true);
    setPurchaseStatus((prev) => ({
      ...prev,
      text: "",
      minting: 0,
      show: true,
      transfering: 1,
      errorStep: 0,
    }));
    let errorStep = 1;
    try {
      const connection = await web3ConnectionWallet();
      const chainId = await connection.eth.getChainId();
      const checked = await checkSwitchNetwork(chain_type, chainId);
      if (!checked) {
        setDisabledPurchase(false);
        setPurchaseStatus((prev) => ({
          ...prev,
          show: false,
          transfering: 1,
          minting: 1,
        }));
        return;
      }
      const userAccount = await connection.eth.getAccounts();

      let fromAccount = userAccount[0];
      let supportAmount = (Number(data.support_amount) * qty).toFixed(6);
      supportAmount = connection.utils.toWei(`${supportAmount}`, "ether");

      setPurchaseStatus((prev) => ({
        ...prev,
        show: true,
        transfering: 2,
        minting: 1,
      }));
      errorStep = 2;

      if (chain_type == "polygon") {
        const contract = await new connection.eth.Contract(
          CONTRACT_WETH_ABI,
          CONTRACT_WETH_ADDRESS,
          {
            from: fromAccount,
          },
        );

        const allowance = await contract.methods
          .allowance(fromAccount, data.contract_address)
          .call();

        if (allowance < supportAmount) {
          await contract.methods
            .approve(data.contract_address, supportAmount)
            .send()
            .then(function (receipt) {
              return receipt;
            });
        }

        const _contract = await new connection.eth.Contract(
          CONTRACT_ERC165_ABI,
          data.contract_address,
          { from: fromAccount },
        );

        const _response = await _contract.methods
          .minterMint(fromAccount, qty, supportAmount, data.id)
          .send()
          .then(function (receipt) {
            return receipt;
          });

        if (_response && _response.transactionHash) {
          let _text =
            locale == "en" && data.message_purchased_en
              ? data.message_purchased_en
              : data.message_purchased_jp;
          setPurchaseStatus((prev) => ({
            ...prev,
            show: true,
            transfering: 2,
            type: "success",
            text: _text,
            minting: 2,
            errorStep: 0,
            transactionHash: _response.transactionHash,
          }));
          loadBlockLatest();
        } else {
          setPurchaseStatus({
            ...purchaseStatus,
            show: true,
            type: "danger",
            text: t("errors.txn_canceled"),
            minting: 0,
            errorStep,
          });
        }
      } else {
        const _contract = await new connection.eth.Contract(
          CONTRACT_ERC165_ABI,
          data.contract_address,
          { from: fromAccount },
        );
        const _response = await _contract.methods
          .minterMint(fromAccount, qty, supportAmount, data.id)
          .send({
            value: supportAmount,
          })
          .then(function (receipt) {
            return receipt;
          });

        if (_response && _response.transactionHash) {
          let _text =
            locale == "en" && data.message_purchased_en
              ? data.message_purchased_en
              : data.message_purchased_jp;
          setPurchaseStatus((prev) => ({
            ...prev,
            show: true,
            transfering: 2,
            type: "success",
            text: _text,
            minting: 2,
            errorStep: 0,
            transactionHash: _response.transactionHash,
          }));
          loadBlockLatest();
        } else {
          setPurchaseStatus({
            ...purchaseStatus,
            show: true,
            type: "danger",
            text: t("errors.txn_canceled"),
            minting: 0,
            errorStep,
          });
        }
      }
    } catch (error) {
      console.error(error);
      let _text = t("errors.txn_canceled");
      setPurchaseStatus({
        ...purchaseStatus,
        show: true,
        type: "danger",
        text: _text,
        transfering: 0,
        minting: 0,
        errorStep,
      });
    }
    setDisabledPurchase(false);
  };

  const loadBlockLatest = async () => {
    const available = await checkChainAvailable(chain_type);
    if (!available) {
      return;
    }
    const connection = await web3Connection();
    const _contract = await new connection.eth.Contract(
      CONTRACT_ERC165_ABI,
      data.contract_address,
    );

    let fromBlock = await connection.eth.getBlockNumber();
    const events = await _contract.getPastEvents(
      "Funding",
      {
        fromBlock:
          fromBlock - 999 > data.block_number
            ? fromBlock - 999
            : data.block_number + 1,
        toBlock: "latest",
      },
      (error, events) => {
        return events;
      },
    );

    const prevBlock = data.block_number;
    let latestBlock = data.block_number;
    const txns = events.map((o) => {
      if (
        latestBlock < o.blockNumber &&
        data.id === Number(o.returnValues.refId)
      ) {
        latestBlock = o.blockNumber;
      }
      return {
        txn_hash: o.transactionHash,
        minter: o.returnValues.minter,
        amount: connection.utils.fromWei(
          `${o.returnValues.ethAmount}`,
          "ether",
        ),
        qty: o.returnValues.quantity,
        ref_id: o.returnValues.refId,
      };
    });

    const totalPurchased = await _contract.methods.totalSales(data.id).call();
    if (prevBlock === latestBlock && totalPurchased == data.total_purchased) {
      return;
    }

    projectPurchase({
      id: data.id,
      data: {
        total_purchased: totalPurchased,
        block_number: latestBlock,
        chain_type: chain_type,
        txns: txns,
      },
      cb: (resp) => {
        props.loadProjectDetail();
      },
    });
  };

  return (
    <>
      <article className="p-benefits__item">
        <div className="p-benefits__item__img">
          <img src={data.nft_image} alt="" />
        </div>
        <div className="p-benefits__item__txt">
          {data.total_purchased ? (
            <a
              href={`${OPENSEA_URL}/assets/${
                chain_type == "polygon"
                  ? IS_TESTNET_ENV == 1
                    ? "mumbai"
                    : "matic"
                  : IS_TESTNET_ENV == 1
                    ? "sepolia"
                    : "ethereum"
              }/${data.contract_address}/1`}
              target="_blank"
              className="pull-right"
            >
              <img src={iconOpensea} style={{ width: 35 }} />
            </a>
          ) : (
            ""
          )}
          <h3>
            {locale == "en" && data.title_en ? data.title_en : data.title_jp}
          </h3>
          <div className="p-benefits__spec">
            <div className="p-benefits__limit">
              <h3>{t("project.status")}</h3>
              <div className="p-video__limit__txt">
                <h4
                  className={`value value1 ${!extendExpDate && "active"}`}
                  onClick={() => setExtendExpDate(true)}
                >
                  {remainDays()}
                </h4>
                <h4
                  className={`value value2 ${extendExpDate && "active"}`}
                  onClick={() => setExtendExpDate(false)}
                >
                  {remainDayFull()}
                </h4>
              </div>
            </div>
            <div className="p-benefits__subscription">
              <h3>
                {t("project.applied_no")}
                <b>
                  {t("project.limit_support", {
                    limit: data.unlimited_support_qty
                      ? "∞"
                      : data.limit_support_qty,
                  })}
                </b>
              </h3>
              <h4 className="value">
                {data.total_purchased}
                <span>
                  /{" "}
                  {`${
                    data.unlimited_max_support_qty == 1
                      ? "∞"
                      : data.max_support_qty
                  }`}
                </span>
              </h4>
            </div>
          </div>
          <div className="p-benefits__support">
            <div className="p-benefits__qty">
              <p>
                {window.$helpers.currency(chain_type, data.support_amount)} x
              </p>
              <Selection
                id=""
                name="qty"
                onChange={handleSelectQty}
                customClass=""
                options={optionQty}
                value={qty}
              />
            </div>
            {connectedAddress ? (
              <>
                {data.is_login_line && !profile.line_user_id ? (
                  <button
                    type="button"
                    className="line-button"
                    onClick={() => props.redirectLoginLine()}
                  >
                    <b className="strong">{t("project.support")}</b>
                    <img src={require("src/assets/images/btn_line_base.png")} />
                    {t("common.login_line")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="c-button c-hover--highlight"
                    disabled={disabledPurchase}
                    onClick={() => handlePurchase(data.id)}
                  >
                    {t("project.support")}
                    {data.my_supported > 0 && (
                      <span className="text-success">
                        (
                        {data.my_supported > 1
                          ? t("project.amount_supports", {
                              amount: data.my_supported,
                            })
                          : t("project.amount_support", {
                              amount: data.my_supported,
                            })}
                        )
                      </span>
                    )}
                  </button>
                )}
              </>
            ) : (
              <button
                type="button"
                className="c-button c-hover--highlight"
                onClick={() => onConnectWallet()}
              >
                {t("common.connect_wallet")}
              </button>
            )}
          </div>
        </div>
      </article>
      <Modal
        title=""
        disableOK={true}
        show={purchaseStatus.show}
        hideFooter={true}
        onClose={() =>
          setPurchaseStatus({
            ...purchaseStatus,
            show: false,
          })
        }
      >
        {purchaseStatus && purchaseStatus.text && (
          <div className={`alert alert-${purchaseStatus.type}`}>
            {purchaseStatus.text}
          </div>
        )}
        <div
          className={`p-2 ${
            purchaseStatus.errorStep == 2 ? "text-danger" : ""
          }`}
        >
          <div className="tbl">
            {purchaseStatus.minting == 0 && (
              <>
                <div className="tr">
                  <div className="td">
                    <CheckedDefault />
                  </div>
                  <div className="td">
                    <b> Mint</b>
                  </div>
                </div>
              </>
            )}
            {purchaseStatus.minting == 1 && (
              <>
                <div className="tr">
                  <div className="td">
                    <Spinner />
                  </div>
                  <div className="td">
                    <b> Mint</b>
                  </div>
                </div>
              </>
            )}
            {purchaseStatus.minting == 2 && (
              <>
                <div className="tr">
                  <div className="td">
                    <CheckedSuccess />
                  </div>
                  <div className="td">
                    <b> Minted</b>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WalletProvider(Index);
