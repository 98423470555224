import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WalletProvider from "../../hoc/WalletProvider";
import { Helmet } from "react-helmet";

function Header(props) {
  const { onConnectWallet, walletAddress, setting, locale, hideSwitchLocale } =
    props;
  const [account, setAccount] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (walletAddress && walletAddress.length > 5) {
      let acc =
        walletAddress.substr(0, 5) +
        "..." +
        walletAddress.substr(walletAddress.length - 4);
      setAccount(acc);
    } else {
      setAccount(walletAddress);
    }
  }, [walletAddress]);

  useEffect(() => {
    if (locale && i18n.language !== locale) {
      i18n.changeLanguage(locale);
      localStorage.setItem("locale", locale);
    }
  }, [locale]);

  const handleSwitchLocale = (locale) => {
    props.setLocale(locale);
    i18n.changeLanguage(locale);
    localStorage.setItem("locale", locale);
    window.$cookies.set("locale", locale);
  };

  return (
    <>
      <Helmet>
        <title>
          {locale == "en" && setting?.collection_name_en
            ? setting?.collection_name_en
            : setting?.collection_name}
        </title>
      </Helmet>
      <header>
        <h1>
          <a href="/">
            {setting?.title_logo ? (
              <img
                src={setting?.title_logo}
                style={{ maxHeight: 45, maxWidth: "75%" }}
                alt={setting?.collection_name}
              />
            ) : (
              <>{setting?.collection_name}</>
            )}
          </a>
        </h1>
        <div className="l-header__nav">
          <ul className="l-social">
            <li>
              <a href={setting?.official_website} target="_blank">
                <img
                  src={require("../../assets/images/ic_home.png")}
                  alt="Home"
                />
              </a>
            </li>
            {setting?.twitter_url && (
              <li>
                <a href={setting.twitter_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_tw.png")}
                    alt="Twitter"
                  />
                </a>
              </li>
            )}
            {setting?.discord_url && (
              <li>
                <a href={setting.discord_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_discord.png")}
                    alt="Discord"
                  />
                </a>
              </li>
            )}
            {setting?.opensea_url && (
              <li>
                <a href={setting.opensea_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_open.png")}
                    alt="Opensea"
                  />
                </a>
              </li>
            )}
          </ul>
          {!hideSwitchLocale ? (
            <>
              {locale == "en" ? (
                <ul className="l-lang">
                  <li>
                    <a href="#" onClick={() => handleSwitchLocale("ja")}>
                      JA
                    </a>
                  </li>
                  <li className="active">EN</li>
                </ul>
              ) : (
                <ul className="l-lang">
                  <li className="active">JA</li>
                  <li>
                    <a href="#" onClick={() => handleSwitchLocale("en")}>
                      EN
                    </a>
                  </li>
                </ul>
              )}
            </>
          ) : (
            <ul className="l-lang"></ul>
          )}
          {account ? (
            <a href="#" className="l-wallet c-hover--highlight">
              {account}
            </a>
          ) : (
            <a
              href="#"
              onClick={() => onConnectWallet()}
              className="l-wallet c-hover--highlight"
            >
              WALLET CONNECT
            </a>
          )}
        </div>
        <div className="l-menu__btn" id="l-js__btn">
          <div id="l-menu">
            <a id="demo01" href="#content01">
              <div className="l-menu__trigger">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}

export default WalletProvider(Header);
