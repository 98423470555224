const CONTRACT_ERC165_ABI =
  require("../contracts/CryptoFunding.sol/CryptoFunding.json").abi;
const CONTRACT_WETH_ABI = require("../contracts/WETH.sol/WETH.json");
const CONTRACT_WETH_ADDRESS = process.env.REACT_APP_CONTRACT_WETH_ADDRESS;

const ERC1155_INTERFACE_ID = "0xd9b67a26";
const ERC721_INTERFACE_ID = "0x80ac58cd";

const IS_TESTNET_ENV = Number(process.env.REACT_APP_IS_TESTNET_ENV);

const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;

export {
  CONTRACT_ERC165_ABI,
  ERC721_INTERFACE_ID,
  ERC1155_INTERFACE_ID,
  IS_TESTNET_ENV,
  CONTRACT_WETH_ADDRESS,
  CONTRACT_WETH_ABI,
  OPENSEA_URL,
};
