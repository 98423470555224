const initialState = {
  projects: [],
  project: {
    loading: true,
  },
};

// Use the initialState as a default value
export default function settingReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "LIST_PROJECT":
      return {
        ...state,
        projects: action.payload,
      };
    case "PROJECT_DETAIL":
      return {
        ...state,
        project: action.payload,
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
