export const getSetting = (payload) => async (dispatch) => {
  const response = await window.$axios
    ._get({
      url: "/api/v1/setting",
      parmas: payload,
    })
    .then((response) => response.data)
    .catch((error) => {
      return {
        data: {
          status: 500,
        },
      };
    });

  return dispatch({
    type: "GET_SETTING",
    payload: response.data,
  });
};

export const setLocale = (payload) => async (dispatch) => {
  return dispatch({
    type: "SET_LOCALE",
    payload: payload,
  });
};

export const setHideSwitchLocale = (payload) => async (dispatch) => {
  return dispatch({
    type: "HIDE_SWITCH_LOCALE",
    payload: payload,
  });
};
