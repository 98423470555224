import { createBrowserRouter } from "react-router-dom";
import Top from "../modules/top";
import Page404 from "../modules/errors/404";
import Project from "../modules/project";

const routes = [
  {
    path: "/",
    element: <Top />,
  },
  {
    path: "/project/:projectId",
    element: <Project />,
  },
  {
    path: "/:urlDirectoryName",
    element: <Project />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default createBrowserRouter(routes);
