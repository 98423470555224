import React from "react";
import { RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import i18n from "./i18n";
import router from "./routes";
import store from "./store";
import Header from "./components/layouts/header";
import Footer from "./components/layouts/footer";
import "./assets/css/index.scss";
import * as axios from "./services/axios";
import * as helpers from "./services/helpers";
import moment from "moment";
import Cookies from "js-cookie";
import $ from "jquery";
window.$ = window.jQuery = $;
require("./assets/js/script");
window.$axios = axios;
window.$helpers = helpers;
moment.locale("ja");
window.$moment = moment;
window.$cookies = Cookies;

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Header />
        <div className="main-top">
          <RouterProvider router={router} />
        </div>
        <Footer />
      </I18nextProvider>
    </Provider>
  );
}

export default App;
