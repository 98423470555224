import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import settingReducer from "./settingReducer";
import projectReducer from "./projectReducer";

const rootReducer = combineReducers({
  accountReducer,
  settingReducer,
  projectReducer,
});

export default rootReducer;
