import React from "react";
import { connect } from "react-redux";
import {
  detectCurrentProvider,
  web3Connection,
  web3ConnectionWallet,
} from "../provider";
import { accountAction, settingAction } from "../actions";
import { bindActionCreators } from "redux";

export default function WalletProvider(WrappedComponent) {
  class Wrapped extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isConnected: true,
      };
    }

    async onConnect() {
      const checkConnected = async () => {
        const connection = await web3Connection();
        const accounts = await connection.eth.getAccounts();
        if (accounts && accounts.length > 0) {
          this.onConnectAccount();
        } else {
          this.props.loginWallet("");
          window.localStorage.setItem("isConnected", 0);
          this.setState({
            isConnected: false,
          });
        }
      };

      const isConnected = window.localStorage.getItem("isConnected");
      if (window.ethereum && isConnected == 1) {
        checkConnected();
        window.ethereum.on("accountsChanged", async () => {
          checkConnected();
        });
      } else {
        window.localStorage.setItem("isConnected", 0);
        this.setState({
          isConnected: false,
        });
      }
    }

    onConnectAccount = async () => {
      try {
        const connection = await web3ConnectionWallet();
        const userAccount = await connection.eth.getAccounts();
        this.setState({
          accountAddress: userAccount[0],
        });
        this.props.loginWallet(userAccount[0]);
      } catch (err) {
        console.error(err);
        this.setState({
          isConnected: false,
        });
        this.props.loginWallet("");
      }
    };

    componentDidMount() {
      this.onConnect();
    }

    onConnectWallet = async () => {
      if (window.$helpers.isMobile() && !window.ethereum) {
        window.open(`https://metamask.app.link/dapp/${document.domain}`);
        return;
      }
      try {
        const currentProvider = await detectCurrentProvider();
        if (currentProvider) {
          if (currentProvider !== window.ethereum) {
            console.log(
              "Non-Ethereum browser detected. You should consider trying MetaMask!",
            );
          }
          let connection = await web3ConnectionWallet();
          const userAccount = await connection.eth.getAccounts();
          if (userAccount.length > 0) {
            window.localStorage.setItem("isConnected", 1);
            this.onConnectAccount();
          }
        }
      } catch (err) {
        console.log(
          "There was an error fetching your accounts. Make sure your Ethereum client is configured correctly.",
        );
      }
    };

    async redirectLoginLine() {
      const { data } = await accountAction.lineLoginUrl({
        intend_url: window.location.href,
      });
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      }
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            setting={this.props.setting}
            locale={this.props.locale}
            setLocale={this.props.setLocale}
            hideSwitchLocale={this.props.hideSwitchLocale}
            walletAddress={this.props.walletAddress}
            onConnectWallet={this.onConnectWallet}
            web3Connection={web3Connection}
            redirectLoginLine={this.redirectLoginLine}
          />
        </>
      );
    }
  }

  const mapStateToProps = ({ accountReducer, settingReducer }) => {
    return {
      walletAddress: accountReducer.walletAddress,
      setting: settingReducer.setting,
      locale: settingReducer.locale,
      hideSwitchLocale: settingReducer.hideSwitchLocale,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      ...bindActionCreators(accountAction, dispatch),
      ...bindActionCreators(settingAction, dispatch),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Wrapped);
}
