import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Index(props) {
  const { t } = useTranslation();
  const { data, locale } = props;
  const [completed, setCompleted] = useState(0);
  const [extendExpDate, setExtendExpDate] = useState(false);

  useEffect(() => {
    let purchased =
      (Number(data.total_purchased_amount) / Number(data.target_amount)) * 100;
    if (purchased % 1 != 0) {
      purchased = purchased.toFixed(2);
    }
    setCompleted(purchased);
  }, [data]);

  const remainDays = (endDate) => {
    const date = window.$moment(endDate);
    const now = window.$moment();
    const days = date.diff(now, "days");

    // - More than 1 day: あとd日​
    // - Less than 1 day: あとh時間:mm分​
    // - Less than 1 hour: あとm分​
    // - Less than 1 min: あとs秒
    // Close: 終了
    if (now.isAfter(date)) {
      return (
        <>
          <span>{t("project.closed")}</span>
        </>
      );
    } else if (days > 0) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.close_day", { count: days }),
          }}
        ></div>
      );
    } else {
      const seconds = date.diff(now, "seconds");
      const hrs = `${Math.floor(seconds / 3600)}`.padStart(2, "0");
      const mins = `${Math.floor((seconds % 3600) / 60)}`.padStart(2, "0");
      const secs = `${Math.floor(seconds % 60)}`.padStart(2, "0");
      if (hrs > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_hour", {
                hour: hrs,
                minute: mins,
              }),
            }}
          ></div>
        );
      } else if (mins > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_minute", {
                count: Number(mins),
              }),
            }}
          ></div>
        );
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_second", {
                count: Number(secs),
              }),
            }}
          ></div>
        );
      }
    }
  };

  const remainDayFull = (endDate) => {
    const date = window.$moment(endDate);
    let format = "YYYY/MM/DD HH:mm:ss";
    const strDate = date.format(format);
    if (date.isBefore()) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.closed_datetime", {
              datetime: strDate,
            }),
          }}
        ></div>
      );
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t("period.close_datetime", {
            datetime: strDate,
          }),
        }}
      ></div>
    );
  };

  return (
    <>
      <article className="p-project">
        <Link
          to={data.url_directory_name ?? `/project/${data.project_id}`}
          className="p-project__cover"
        >
          <img src={data.image} alt={data.name_jp} />
        </Link>
        <div className="p-project__detail">
          <div className="p-project__txt">
            <h2>
              {locale == "en" && data.name_en ? data.name_en : data.name_jp}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: `${
                  locale == "en" &&
                  window.$helpers.htmlToText(data.explanation_en)
                    ? window.$helpers.htmlToText(data.explanation_en, 100)
                    : window.$helpers.htmlToText(data.explanation_jp, 100)
                }`,
              }}
            ></p>
          </div>
          <div className="p-project__spec">
            <div className="p-project__limit">
              <h3
                dangerouslySetInnerHTML={{
                  __html: t("project.status"),
                }}
              ></h3>
              <div className="p-video__limit__txt">
                <h4
                  className={`value value1 ${!extendExpDate && "active"}`}
                  onClick={() => setExtendExpDate(true)}
                >
                  {remainDays(data.end_datetime)}
                </h4>
                <h4
                  className={`value value2 ${extendExpDate && "active"}`}
                  onClick={() => setExtendExpDate(false)}
                >
                  {remainDayFull(data.end_datetime)}
                </h4>
              </div>
            </div>
            <p>{t("project.closing_times_differ_for_each_benefit")}</p>
            <div className="p-project__fund">
              <div className="p-project__entry">
                <h3>{t("project.raised")}</h3>
                <h4 className="value">
                  {data.total_purchased_amount}
                  <span>{window.$helpers.currencySymbol(data.chain_type)}</span>
                </h4>
              </div>
              <div className="p-project__goal">
                <h3>{t("project.goal")}</h3>
                <h4 className="value">
                  {data.target_amount}
                  <span>{window.$helpers.currencySymbol(data.chain_type)}</span>
                </h4>
              </div>
              <div className="p-project__fund-meter">
                <span
                  className={`p-project__fund-meter--${completed > 0 ? (completed > 100 ? "100" : "78") : 0}`}
                  style={{
                    width: `${completed > 100 ? 100 : completed}%`,
                    minWidth: "fit-content",
                  }}
                >
                  &nbsp; {completed}%
                </span>
              </div>
            </div>
          </div>
          <Link
            to={data.url_directory_name ?? `/project/${data.project_id}`}
            className="c-button c-button--outline"
          >
            {t("project.details")}
          </Link>
        </div>
      </article>
    </>
  );
}
