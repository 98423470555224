import CommonHoc from "../../hoc/CommonHoc";
import SectionHead from "./components/section-head";
import { connect } from "react-redux";
import { projectAction } from "../../actions";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import SectionProject from "./components/section-project";

function Index(props) {
  const { setting } = props;
  useEffect(() => {
    props.getProjects();
  }, []);

  useEffect(() => {
    if (setting) {
      if (!setting?.description_en) {
        props.setLocale("ja");
        props.setHideSwitchLocale(true);
      } else {
        props.setHideSwitchLocale(false);
      }
    }
  }, [setting]);

  return (
    <>
      <div className="p-top">
        <SectionHead {...props} />
        <SectionProject {...props} />
      </div>
    </>
  );
}

const mapStateToProps = ({ projectReducer }) => {
  return {
    projects: projectReducer.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(projectAction, dispatch);
};

export default CommonHoc(connect(mapStateToProps, mapDispatchToProps)(Index));
