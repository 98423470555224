const initialState = {
  setting: null,
  locale: "ja",
  hideSwitchLocale: false,
};

// Use the initialState as a default value
export default function settingReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "GET_SETTING":
      return {
        ...state,
        setting: action.payload,
      };
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload,
      };
    case "HIDE_SWITCH_LOCALE":
      return {
        ...state,
        hideSwitchLocale: action.payload,
      };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
