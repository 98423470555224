export const getProjects = (payload) => async (dispatch) => {
  const response = await window.$axios
    ._get({
      url: "/api/v1/project",
      parmas: payload,
    })
    .then((response) => response.data)
    .catch((error) => {
      return {
        data: {
          status: 500,
        },
      };
    });

  return dispatch({
    type: "LIST_PROJECT",
    payload: response.data,
  });
};

export const getProjectDetail =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await window.$axios
      ._get({
        url: `/api/v1/project/${id}`,
        params: params,
      })
      .then((response) => response.data)
      .catch((error) => {
        return {
          data: {
            status: error?.response?.status ?? 500,
            message: error.response?.statusText,
          },
        };
      });

    return dispatch({
      type: "PROJECT_DETAIL",
      payload: response.data,
    });
  };

export const getProjectCollection = async (id) => {
  const response = await window.$axios
    ._get({
      url: `/api/v1/project-collection/${id}`,
    })
    .then((response) => response.data)
    .catch((error) => {
      return {
        data: {
          status: error?.response?.status ?? 500,
          message: error.response?.statusText,
        },
      };
    });

  return response.data;
};

export const projectPurchase = async ({ data, id, cb }) => {
  const response = await window.$axios
    ._post({
      url: `/api/v1/purchase/${id}`,
      data: data,
    })
    .then((response) => response.data)
    .catch((error) => {
      return {
        meta: {
          code: error?.code ? error?.code : 500,
          message: error.message,
        },
      };
    });

  cb(response);
};
