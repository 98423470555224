import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const compileStrings = (ctx) => {
  const compiled = { resources: {}, ns: [] };
  // This regex does not work for en-US, en-UK, etc.
  const fileRegex =
    /(?<context>\w*)\/(?<namespace>\w+)\/(?<fileName>\w+)\.(?<locale>\w+)\.json$/;
  ctx.keys().forEach((file) => {
    const { fileName, namespace, locale } = file.match(fileRegex).groups;
    compiled.ns.push(namespace);
    compiled.resources[locale] = !!compiled.resources[locale]
      ? compiled.resources[locale]
      : {};
    compiled.resources[locale][fileName] = ctx(file);
  });

  // Filter unique namespaces
  compiled.ns = compiled.ns.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return compiled;
};

const strings = compileStrings(
  require.context("./locales", true, /\.\w+\.json$/),
);

const locale = localStorage.getItem("locale") ?? "ja";
i18n.use(initReactI18next).init({
  resources: strings.resources,
  ns: strings.ns,
  defaultNS: ["common"],
  lng: locale,
  fallbackLng: "ja",
  detection: {
    order: ["localStorage"],
  },
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
