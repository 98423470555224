import { connect } from "react-redux";
import CommonHoc from "../../hoc/CommonHoc";
import { projectAction } from "../../actions";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SectionHead from "./components/section-head";
import SectionExplanation from "./components/section-explanation";
import SectionCollection from "./components/section-collection";
import Page404 from "../errors/404";
import Spinner from "../../components/spinner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function Index(props) {
  const { connectedAddress, project, locale } = props;
  const { projectId, urlDirectoryName } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    loadProjectDetail();
  }, [connectedAddress]);

  const loadProjectDetail = () => {
    props.getProjectDetail(projectId ?? 0, {
      wallet_address: connectedAddress,
      url_directory_name: urlDirectoryName,
    });
  };

  useEffect(() => {
    if (!project.loading && project?.status !== 200) {
      if (!project?.name_en) {
        props.setLocale("ja");
        props.setHideSwitchLocale(true);
      } else {
        props.setHideSwitchLocale(false);
      }
    }
  }, [project]);

  return (
    <>
      <Helmet>
        <title>
          {locale == "en" && project?.name_en
            ? project?.name_en
            : project?.name_jp}
        </title>
      </Helmet>
      {props.project?.loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          {props.project?.status === 404 ? (
            <Page404 />
          ) : (
            <div className="p-detail">
              <SectionHead {...props} />
              <div className="l-content">
                <SectionExplanation {...props} />
                <SectionCollection
                  {...props}
                  loadProjectDetail={loadProjectDetail}
                />
                <a href="/" className="c-link--back">
                  {t("common.back_to_top")}
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ projectReducer, accountReducer }) => {
  return {
    project: projectReducer.project,
    connectedAddress: accountReducer.walletAddress,
    profile: accountReducer.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(projectAction, dispatch);
};

export default CommonHoc(connect(mapStateToProps, mapDispatchToProps)(Index));
