import * as Crypto from "src/services/crypto";

export const loginWallet = (payload) => async (dispatch) => {
  const logging = window.$cookies.get("logging");
  if (logging) {
    return;
  }
  window.$cookies.set("logging", 1, {
    expires: new window.$moment().add(5, "seconds").toDate(),
  });
  if (!payload) {
    return dispatch({
      type: "WALLET_ADDRESS",
      payload: {},
    });
  }
  const response = await window.$axios
    ._post({
      url: "/api/v1/user/login",
      data: {
        wallet_address: Crypto.encrypt(payload),
      },
    })
    .then((response) => response.data)
    .catch((error) => error.response);

  const { data } = response;
  if (data && data.token) {
    window.$cookies.set("token", data.token);
    delete data.token;
    return dispatch({
      type: "WALLET_ADDRESS",
      payload: data,
    });
  } else {
    return dispatch({
      type: "WALLET_ADDRESS",
      payload: {},
    });
  }
};

export const lineLoginUrl = async (payload) => {
  return await window.$axios
    ._post({
      url: "/api/v1/user/line-login-url",
      data: payload,
    })
    .then((response) => response.data)
    .catch((error) => error.response);
};
