function Index(props) {
  const { project, locale } = props;

  return (
    <>
      <section className="p-freearea">
        {locale == "en" && project.explanation_en ? (
          <div
            dangerouslySetInnerHTML={{ __html: project.explanation_en }}
          ></div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: project.explanation_jp }}
          ></div>
        )}
      </section>
    </>
  );
}

export default Index;
