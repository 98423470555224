import React from "react";
import { connect } from "react-redux";
import { settingAction } from "../actions";
import { bindActionCreators } from "redux";

export default function CommonHoc(WrappedComponent) {
  class Wrapped extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const locale = localStorage.getItem("locale") ?? "ja";
      this.props.setLocale(locale);
      this.props.getSetting();
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.state}
            {...this.props}
            setting={this.props.setting}
            locale={this.props.locale}
            hideSwitchLocale={this.props.hideSwitchLocale}
          />
        </>
      );
    }
  }

  const mapStateToProps = ({ settingReducer }) => {
    return {
      setting: settingReducer.setting,
      locale: settingReducer.locale,
      hideSwitchLocale: settingReducer.hideSwitchLocale,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(settingAction, dispatch);
  };

  return connect(mapStateToProps, mapDispatchToProps)(Wrapped);
}
