export default function Index() {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width="24"
      height="24"
      xlmns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5303 7.46967C18.8232 7.76256 18.8232 8.23744 18.5303 8.53033L10 17.0607L5.46967 12.5303C5.17678 12.2374 5.17678 11.7626 5.46967 11.4697C5.76256 11.1768 6.23744 11.1768 6.53033 11.4697L10 14.9393L17.4697 7.46967C17.7626 7.17678 18.2374 7.17678 18.5303 7.46967Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
