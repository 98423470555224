import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WalletProvider from "../../hoc/WalletProvider";

function Footer(props) {
  const { onConnectWallet, walletAddress, setting, locale, hideSwitchLocale } =
    props;
  const [account, setAccount] = useState();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (walletAddress && walletAddress.length > 5) {
      let acc =
        walletAddress.substr(0, 5) +
        "..." +
        walletAddress.substr(walletAddress.length - 4);
      setAccount(acc);
    } else {
      setAccount(walletAddress);
    }
  }, [walletAddress]);

  const handleSwitchLocale = (locale) => {
    props.setLocale(locale);
    i18n.changeLanguage(locale);
    localStorage.setItem("locale", locale);
    window.$cookies.set("locale", locale);
  };

  return (
    <>
      <footer className="footer">
        <div className="l-footer__left">
          <ul className="l-footer__nav">
            {setting?.privacy_url && (
              <li>
                <a
                  href={
                    locale == "en" && setting?.privacy_url_en
                      ? setting?.privacy_url_en
                      : setting?.privacy_url
                  }
                  target="_blank"
                >
                  {t("common.policy")}
                </a>
              </li>
            )}
            {setting?.term_of_use_url && (
              <li>
                <a
                  href={
                    locale == "en" && setting?.term_of_use_url_en
                      ? setting?.term_of_use_url_en
                      : setting?.term_of_use_url
                  }
                  target="_blank"
                >
                  {t("common.term")}
                </a>
              </li>
            )}
            {setting?.commercial_law_url && (
              <li>
                <a
                  href={
                    locale == "en" && setting?.commercial_law_url_en
                      ? setting?.commercial_law_url_en
                      : setting?.commercial_law_url
                  }
                  target="_blank"
                >
                  {t("common.commercial_transaction")}
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="l-footer__right">
          <ul className="l-social">
            <li>
              <a href={setting?.official_website} target="_blank">
                <img
                  src={require("../../assets/images/ic_home.png")}
                  alt="Home"
                />
              </a>
            </li>
            {setting?.twitter_url && (
              <li>
                <a href={setting.twitter_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_tw.png")}
                    alt="Twitter"
                  />
                </a>
              </li>
            )}
            {setting?.discord_url && (
              <li>
                <a href={setting.discord_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_discord.png")}
                    alt="Discord"
                  />
                </a>
              </li>
            )}
            {setting?.opensea_url && (
              <li>
                <a href={setting.opensea_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_open.png")}
                    alt="Opensea"
                  />
                </a>
              </li>
            )}
          </ul>
        </div>
        <p className="credit">{setting?.copyright_text}</p>
        {/* <address>produced by Crypto Funding</address> */}
      </footer>

      {!account && (
        <div className="l-wallet__footer">
          <a href="#" onClick={() => onConnectWallet()} className="l-wallet">
            WALLET CONNECT
          </a>
        </div>
      )}

      <nav className="l-menu" id="l-js__nav">
        <div className="l-menu__inner">
          <ul className="l-social">
            <li>
              <a href={setting?.official_website} target="_blank">
                <img
                  src={require("../../assets/images/ic_home.png")}
                  alt="Home"
                />
              </a>
            </li>
            {setting?.twitter_url && (
              <li>
                <a href={setting.twitter_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_tw.png")}
                    alt="Twitter"
                  />
                </a>
              </li>
            )}
            {setting?.discord_url && (
              <li>
                <a href={setting.discord_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_discord.png")}
                    alt="Discord"
                  />
                </a>
              </li>
            )}
            {setting?.opensea_url && (
              <li>
                <a href={setting.opensea_url} target="_blank">
                  <img
                    src={require("../../assets/images/ic_open.png")}
                    alt="Opensea"
                  />
                </a>
              </li>
            )}
          </ul>
          {!hideSwitchLocale && (
            <>
              {locale == "en" ? (
                <ul className="l-lang">
                  <li>
                    <a href="#" onClick={() => handleSwitchLocale("ja")}>
                      JA
                    </a>
                  </li>
                  <li className="active">EN</li>
                </ul>
              ) : (
                <ul className="l-lang">
                  <li className="active">JA</li>
                  <li>
                    <a href="#" onClick={() => handleSwitchLocale("en")}>
                      EN
                    </a>
                  </li>
                </ul>
              )}
            </>
          )}
          {account ? (
            <a href="#" className="l-wallet">
              {account}
            </a>
          ) : (
            <a href="#" onClick={() => onConnectWallet()} className="l-wallet">
              WALLET CONNECT
            </a>
          )}
        </div>
      </nav>
    </>
  );
}

export default WalletProvider(Footer);
