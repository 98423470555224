export default function Index(props) {
  return (
    <div
      className="overlay"
      style={{
        visibility: `${props.show ? "visible" : "hidden"}`,
      }}
    >
      <div className="popup">
        <div className="popup-content">
          <div className="popup-header">
            <h5 className="popup-title" id="exampleModalCenterTitle">
              {props.title}
            </h5>
            {!props.disableClose && (
              <button
                onClick={() => props.onClose()}
                type="button"
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <div className="popup-body">{props.children}</div>
          {!props.hideFooter && (
            <div className="popup-footer">
              {!props.disableClose && (
                <button
                  onClick={() => props.onClose()}
                  type="button"
                  className="btn btn-default"
                >
                  Close
                </button>
              )}
              {!props.disableOK && (
                <button
                  onClick={() => props.onConfirm()}
                  type="button"
                  className="btn btn-primary"
                >
                  OK
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
