import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Index(props) {
  const { t } = useTranslation();
  const { project, locale } = props;
  const [completed, setCompleted] = useState("0");
  const [extendExpDate, setExtendExpDate] = useState(false);
  const [btnPurchase, setBtnPurchase] = useState(true);

  useEffect(() => {
    const endDate = window.$moment(project.end_datetime);
    const now = window.$moment();
    if (now.isAfter(endDate)) {
      setBtnPurchase(false);
    }
    let purchased =
      (Number(project.total_purchased_amount) / Number(project.target_amount)) *
      100;
    if (purchased % 1 != 0) {
      purchased = purchased.toFixed(2);
    }
    setCompleted(purchased);
  }, [project]);

  const remainDays = (endDate) => {
    const date = window.$moment(endDate);
    const now = window.$moment();
    const days = date.diff(now, "days");

    // - More than 1 day: あとd日​
    // - Less than 1 day: あとh時間:mm分​
    // - Less than 1 hour: あとm分​
    // - Less than 1 min: あとs秒
    // Close: 終了
    if (now.isAfter(date)) {
      return (
        <>
          <span>{t("project.closed")}</span>
        </>
      );
    } else if (days > 0) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.close_day", { count: days }),
          }}
        ></div>
      );
    } else {
      const seconds = date.diff(now, "seconds");
      const hrs = `${Math.floor(seconds / 3600)}`.padStart(2, "0");
      const mins = `${Math.floor((seconds % 3600) / 60)}`.padStart(2, "0");
      const secs = `${Math.floor(seconds % 60)}`.padStart(2, "0");
      if (hrs > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_hour", {
                hour: hrs,
                minute: mins,
              }),
            }}
          ></div>
        );
      } else if (mins > 0) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_minute", {
                count: Number(mins),
              }),
            }}
          ></div>
        );
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t("period.close_second", {
                count: Number(secs),
              }),
            }}
          ></div>
        );
      }
    }
  };

  const remainDayFull = (endDate) => {
    const date = window.$moment(endDate);
    let format = "YYYY/MM/DD HH:mm:ss";
    const strDate = date.format(format);
    if (date.isBefore()) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("period.closed_datetime", {
              datetime: strDate,
            }),
          }}
        ></div>
      );
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t("period.close_datetime", {
            datetime: strDate,
          }),
        }}
      ></div>
    );
  };

  return (
    <>
      <div className="p-video__detail">
        <div className="p-video__spec">
          <div className="p-video__limit">
            <h3
              dangerouslySetInnerHTML={{
                __html: t("project.status"),
              }}
            ></h3>
            <div className="p-video__limit__txt">
              <h4
                className={`value value1 ${!extendExpDate && "active"}`}
                onClick={() => setExtendExpDate(true)}
              >
                {remainDays(project.end_datetime)}
              </h4>
              <h4
                className={`value value2 ${extendExpDate && "active"}`}
                onClick={() => setExtendExpDate(false)}
              >
                {remainDayFull(project.end_datetime)}
              </h4>
            </div>
          </div>
          <div className="p-video__fund">
            <div className="p-video__entry">
              <h3>{t("project.raised")}</h3>
              <h4 className="value">
                {project.total_purchased_amount}
                <span>
                  {window.$helpers.currencySymbol(project.chain_type)}
                </span>
              </h4>
            </div>
            <div className="p-video__slash pc">
              <img
                src={require("../../../assets/images/obj_slash.png")}
                alt=""
              />
            </div>
            <div className="p-video__goal">
              <h3>{t("project.goal")}</h3>
              <h4 className="value">
                {project.target_amount}
                <span>
                  {window.$helpers.currencySymbol(project.chain_type)}
                </span>
              </h4>
            </div>
            <div className="p-video__fund-meter">
              <span
                className={`p-video__fund-meter--${completed > 0 ? (completed > 100 ? "100" : "78") : 0}`}
                style={{
                  width: `${completed > 100 ? 100 : completed}%`,
                  minWidth: "fit-content",
                }}
              >
                &nbsp; {completed}%
              </span>
            </div>
          </div>
        </div>
        <a
          href="#p-benefits"
          className={`c-button c-hover--highlight ${btnPurchase === false ? "disabled" : ""}`}
        >
          {btnPurchase === false ? t("project.closed") : t("project.support")}
        </a>
      </div>
    </>
  );
}
