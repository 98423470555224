export default function Index({
  customClass = "form-control",
  options = [],
  onChange,
  name,
  value,
}) {
  return (
    <>
      <select
        className={customClass}
        onChange={onChange}
        name={name}
        value={value}
      >
        {options.map((val, key) => {
          return (
            <option key={key} value={val.value}>
              {val.text}
            </option>
          );
        })}
      </select>
    </>
  );
}
