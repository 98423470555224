import ProjectItem from "./project-item";

export default function (props) {
  const { projects, locale, setting } = props;

  return (
    <>
      <div className="l-content">
        <section className="p-block__txt">
          {locale == "en" ? (
            <div
              dangerouslySetInnerHTML={{ __html: setting?.description_en }}
            ></div>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: setting?.description_jp }}
            ></div>
          )}
        </section>
        <section className="p-project__wrapper">
          {projects.length > 0 &&
            projects.map((data, key) => {
              return <ProjectItem locale={locale} data={data} key={key} />;
            })}
        </section>
      </div>
    </>
  );
}
