import { useTranslation } from "react-i18next";
import CollectionItem from "./collection-item";

export default function Index(props) {
  const { project } = props;
  const { t } = useTranslation();

  return (
    <section id="p-benefits" className="p-benefits">
      <img
        className="p-benefits__bg"
        src={require("../../../assets/images/bg_ba_benefits.png")}
        alt=""
      />
      <h2>{t("project.support_benefits")}</h2>
      {project &&
        project.collections &&
        project.collections.map((data, key) => {
          return (
            <CollectionItem
              {...props}
              data={data}
              key={key}
              chain_type={project.chain_type}
            />
          );
        })}
    </section>
  );
}
