const $ = window.$;
var jQuery = window.$;
//Menu
$(function () {
  //menu
  $("#l-menu a").click(function () {
    if ($(".l-menu__trigger").hasClass("active")) {
      $(".l-menu__trigger").removeClass("active");
      $(".l-menu__trigger span").css("background", "#fff");
    } else {
      $(".l-menu__trigger").addClass("active");
      $(".l-menu__trigger span").css("background", "#fff");
    }
  });
});

$(function () {
  var $body = $("body");
  //開閉用ボタンをクリックでクラスの切替え
  $("#l-js__btn").on("click", function () {
    $body.toggleClass("open");
  });
  //メニュー名以外の部分をクリックで閉じる
  $("#l-js__nav").on("click", function () {
    $body.removeClass("open");
    $(".l-menu__trigger").removeClass("active");
    $(".l-menu__trigger span").css("background", "#fff");
  });
});

//LimitTextchange
$(function () {
  //menu
  $(".p-video__limit__txt").click(function () {
    if ($(".value1", this).hasClass("active")) {
      $(".value1", this).removeClass("active");
      $(".value2", this).addClass("active");
    } else if ($(".value2", this).hasClass("active")) {
      $(".value2", this).removeClass("active");
      $(".value1", this).addClass("active");
    }
  });
});

require("magnific-popup");
