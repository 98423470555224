export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export function currency(chainType, amount = "") {
  if (chainType == "polygon") {
    return `${amount}WETH`;
  }
  return `${amount}ETH`;
}

export function currencySymbol(chainType) {
  if (chainType == "polygon") {
    return `WETH`;
  }
  return `ETH`;
}

export function htmlToText(html, limit) {
  if (!html) {
    return;
  }
  let _text = html.replace(/<[^>]+>/g, "").replace(/\\n/g, "");
  if (limit && _text.length > limit) {
    return `${_text.substring(0, limit)} ...`;
  }
  return _text;
}

export function textToParagrap(text) {
  if (!text) {
    return;
  }
  const _textArr = text.split("\n");
  let _text = [];
  for (let i = 0; i < _textArr.length; i++) {
    _text.push(<p key={i}>{_textArr[i]}</p>);
  }
  return _text;
}
