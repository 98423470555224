import { IS_TESTNET_ENV } from "../config";
const Web3 = require("web3");

const detectCurrentProvider = async () => {
  let provider;
  if (window.ethereum) {
    provider = window.ethereum;
  } else if (window.web3) {
    provider = window.web3.currentProvider;
  }

  if (provider.overrideIsMetaMask && provider.providers.length > 1) {
    const metaMask = provider.providerMap?.get("MetaMask");
    if (metaMask) {
      provider = metaMask;
    } else {
      provider = provider.providers[0];
    }
  }
  return provider;
};

const web3Connection = async () => {
  const currentProvider = await detectCurrentProvider();
  return new Web3(currentProvider);
};

const web3ConnectionWallet = async () => {
  const currentProvider = await detectCurrentProvider();
  const web3 = new Web3(currentProvider);
  await web3.eth.requestAccounts();
  return web3;
};

const checkSwitchNetwork = async (chainType, chainId) => {
  //local dev
  if (IS_TESTNET_ENV == 2) {
    return true;
  }
  let POLYGON_CHAINID = 137;
  let ETHEREUM_CHAINID = 1;
  if (IS_TESTNET_ENV == 1) {
    POLYGON_CHAINID = 80001;
    ETHEREUM_CHAINID = 11155111;
  }

  let checked = true;

  if (chainType == "ethereum" && ETHEREUM_CHAINID != chainId) {
    let _chainId = Web3.utils.toHex(ETHEREUM_CHAINID);
    let rpcUrls = "https://ethereum.publicnode.com";
    let chainName = "Ethereum Mainnet";
    let blockExplorerUrls = "https://etherscan.io";
    let symbol = "ETH";
    if (IS_TESTNET_ENV == 1) {
      rpcUrls = "https://ethereum-sepolia.publicnode.com";
      chainName = "Sepolia test network";
      symbol = "SepoliaETH";
      blockExplorerUrls = "https://sepolia.etherscan.io";
    }

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `${_chainId}`,
          },
        ],
      });
    } catch (error) {
      checked = false;
      if (error.code == 4902 || error.data?.originalError?.code == 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `${_chainId}`,
                rpcUrls: [`${rpcUrls}`],
                chainName: `${chainName}`,
                nativeCurrency: {
                  name: "ETH",
                  symbol: `${symbol}`,
                  decimals: 18,
                },
                blockExplorerUrls: [`${blockExplorerUrls}`],
              },
            ],
          });
          checked = true;
        } catch (error) {}
      }
    }
  } else if (chainType == "polygon" && POLYGON_CHAINID != chainId) {
    let _chainId = Web3.utils.toHex(POLYGON_CHAINID);
    let rpcUrls = "https://rpc-mainnet.matic.network/";
    let chainName = "Matic Mainnet";
    let blockExplorerUrls = "https://polygonscan.com/";
    if (IS_TESTNET_ENV == 1) {
      rpcUrls = "https://rpc-mumbai.maticvigil.com/";
      chainName = "Mumbai Testnet";
      blockExplorerUrls = "https://mumbai.polygonscan.com/";
    }

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `${_chainId}`,
          },
        ],
      });
    } catch (error) {
      checked = false;
      if (error.code == 4902 || error.data?.originalError?.code == 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `${_chainId}`,
                rpcUrls: [`${rpcUrls}`],
                chainName: `${chainName}`,
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: [`${blockExplorerUrls}`],
              },
            ],
          });
          checked = true;
        } catch (error) {}
      }
    }
  }

  return checked;
};

const checkChainAvailable = async (chainType) => {
  if (!window.ethereum && !window.web3) {
    return false;
  } else if (IS_TESTNET_ENV == 2) {
    return true;
  }
  const web3 = await web3Connection();
  const chainId = await web3.eth.getChainId();
  let POLYGON_CHAINID = 137;
  let ETHEREUM_CHAINID = 1;
  if (IS_TESTNET_ENV == 1) {
    POLYGON_CHAINID = 80001;
    ETHEREUM_CHAINID = 11155111;
  }
  if (chainType === "polygon") {
    if (chainId !== POLYGON_CHAINID) {
      return false;
    }
  } else if (ETHEREUM_CHAINID !== chainId) {
    return false;
  }

  return true;
};

export {
  detectCurrentProvider,
  web3Connection,
  web3ConnectionWallet,
  checkSwitchNetwork,
  checkChainAvailable,
};
